import React from 'react';

const Credits = () => {

    return (
        <div id="fatra-credits">
            Powered by <a href="https://google.com" rel="nofollow">Fatra</a> - 2024
        </div>
    );
};

export default Credits;
